<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Booking Availability
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Booking Availability</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="col-2 col-lg-2 col-sm-4">
                    <Select2
                      v-model="searchform.booking_type"
                      :options="booking_type_options"
                      :settings="{
                        placeholder: 'Choose Booking Type',
                      }"
                      :onSelect="searchData"
                    />
                  </div>
                  <div class="col-8 col-lg-8 col-sm-4"></div>
                  <div class="col-2 col-lg-2 col-sm-4">
                    <button
                      type="button"
                      class="btn btn-gradient-primary float-end"
                      @click="add"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card table_scoll">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-show="list.length > 0"
                                v-for="li in list"
                                :key="li.id"
                              >
                                <td style="width: 230px">
                                  {{
                                    li.from_date +
                                    (li.to_date ? " - " + li.to_date : "")
                                  }}
                                </td>
                                <td>
                                  <span v-if="li.booking_type == 'VillaBooking'"
                                    >Villa</span
                                  >
                                  <span v-if="li.booking_type == 'EventBooking'"
                                    >Event</span
                                  >
                                  <span
                                    v-if="li.booking_type == 'PicnicBooking'"
                                    >Picnic</span
                                  >
                                  <span v-if="li.booking_type == 'All'"
                                    >All</span
                                  >
                                </td>
                                <td>
                                  <span v-if="li.booking_type == 'All'"
                                    >All</span
                                  >
                                  <span v-if="li.booking_type == 'VillaBooking'">{{
                                    li.villa ? li.villa.villa_name : 'All Villa'
                                  }}</span>
                                  <span v-if="li.booking_type == 'EventBooking'">{{
                                    li.event ? li.event.name : 'All Event'
                                  }}</span>
                                  <span v-if="li.booking_type == 'PicnicBooking'">{{
                                    li.picnic ? li.picnic.pack_name : 'All Picnic'
                                  }}</span>
                                </td>
                                <td>
                                  {{ li.notes }}
                                </td>
                                <td class="d-flex justify-content-end">
                                  <div class="d-flex align-items-center mb-0">
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="editRow(li)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="edit"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div class="d-flex align-items-center mb-0">
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteRow(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="trash-2"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="16" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex" v-if="paginationData">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                  keepLength="false"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div
      class="modal modal-slide-in new-user-modal fade"
      id="modals-slide-in"
      :class="isShow"
      :style="{ display: isDisplay }"
    >
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          method="post"
          @submit.prevent="submitForm"
        >
          <input type="hidden" name="id" :v-modal="form.id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[(isShow = ''), (isDisplay = 'none')]"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ form.id ? "Edit" : "Add" }}
            </h4>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="mb-1">
              <label for="basic-addon-name" class="form-label"
                >Booking Type</label
              >
              <Select2
                v-model="form.booking_type"
                :options="booking_type_options"
                :settings="{
                  placeholder: 'Choose Booking Type',
                }"
                :onSelect="selectType"
              />
            </div>
            <div
              class="mb-1"
              v-if="form.booking_type && form.booking_type != 'All'"
            >
              <div v-for="item in booking_type_options" :key="item.index">
                <div v-if="item.id == form.booking_type">
                  <Select2
                    v-model="form.package_id"
                    :options="item.packages"
                    :settings="{
                      placeholder: 'Choose Package',
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Date Range</label
              >
              <Datepicker
                type="date"
                name="date"
                @closed="dateChange()"
                @cleared="dateChange()"
                :min="new Date().toISOString().substr(0, 10)"
                v-model="form.rangeDate"
                calendar-button="false"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                :min-date="new Date()"
                modelAuto
                multiCalendars
                range
                multiCalendarsSolo
              />
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Description</label
              >
              <textarea
                v-model="form.description"
                placeholder="Description"
                class="form-control"
              ></textarea>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[(isShow = ''), (isDisplay = 'none')]"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Form from "vform";
import { ref } from "vue";
import moment from "moment";
import Select2 from "vue3-select2-component";

export default {
  name: "Availability",
  components: {
    Pagination: LaravelVuePagination,
    Datepicker,
    Select2,
  },
  data() {
    return {
      header: [],
      list: [],
      paginationData: {},
      searchform: new Form({
        booking_type: null,
      }),
      form: new Form({
        id: null,
        booking_type: null,
        rangeDate: ref(),
        from_date: "",
        to_date: "",
        package_id: null,
        description: "",
      }),
      booking_type_options: [],
      booking_status_options: [
        {
          id: "All",
          text: "All",
        },
        {
          id: "Success",
          text: "Success",
        },
        {
          id: "Pending",
          text: "Pending",
        },
      ],
      booking_details: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      isShow: "",
      isDisplay: "none",
      page: 1,
    };
  },
  async mounted() {
    this.header = ["Date", "Booking Type", "Vila / Package", "Notes", ""];
    this.getResults();
    this.getAvailabiiltyTypes();
  },
  methods: {
    async add() {
      this.form.id = null;
      this.form.booking_type = null;
      this.form.rangeDate = ref();
      this.form.from_date = null;
      this.form.to_date = "";
      this.form.package_id = null;
      this.form.description = "";
      this.isShow = "show";
      this.isDisplay = "block";
    },
    async getAvailabiiltyTypes() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/availability-types", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.booking_type_options = response.data.data;
        });
    },
    dateChange() {
      this.form.from_date = "";
      this.form.to_date = "";
      if (this.form.rangeDate) {
        const date = this.form.rangeDate;
        if (date.length > 0 && date.length != undefined) {
          date.map(() => {
            this.form.from_date = moment(date[0]).format("YYYY-MM-DD");
            this.form.to_date = moment(date[1]).format("YYYY-MM-DD");
          });
        } else {
          if (typeof date != undefined) {
            this.form.from_date = moment(date).format("YYYY-MM-DD");
            this.form.to_date = moment(date).format("YYYY-MM-DD");
          }
        }
      }
    },
    async getResults(page = 1) {
      this.page = page;
      var searchq = "";
      if (this.searchform.booking_type) {
        searchq += "&booking_type=" + this.searchform.booking_type;
      }

      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/list-availability?page=" +
            page +
            searchq,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    editRow(item) {
      this.form.id = item.id;
      this.form.booking_type = item.booking_type;
      this.form.rangeDate = [item.from_date, item.to_date];
      this.form.from_date = item.from_date;
      this.form.to_date = item.to_date;
      if (item.booking_type == "VillaBooking") {
        this.form.package_id = item.villa_type_id;
      }
      if (item.booking_type == "EventBooking") {
        this.form.package_id = item.event_id;
      }
      if (item.booking_type == "PicnicBooking") {
        this.form.package_id = item.picnic_id;
      }
      this.form.description = item.notes;
      this.isShow = "show";
      this.isDisplay = "block";
    },
    deleteRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              process.env.VUE_APP_API_URL + "/delete-availability/",
              {
                id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json", 
                  Accept: "application/json",
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((response) => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async searchData() {
      setTimeout(() => {
        this.getResults();
      }, 300);
    },
    async submitForm() {
      this.disabled = true;

      var apiUrl = "";
      if (this.form.id) {
        apiUrl =
          process.env.VUE_APP_API_URL + "/update-availability";
      } else {
        apiUrl = process.env.VUE_APP_API_URL + "/store-availability";
      }

      await this.form
        .post(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.$toast.success(response.data.message);
            this.isShow = "";
            this.isDisplay = "none";
            this.disabled = false;
            this.getResults();
          } else {
            this.$toast.error(response.data.error?.[0]);
            this.disabled = false;
          }
        })
        .catch((error) => {
          this.$toast.error(error);
          this.disabled = false;
        });
    },
  },
};
</script>
<style scoped>
/* .card-datatable {
    overflow-x: auto;
  } */

.table > :not(caption) > * > * {
  padding: 0.72rem 1rem !important;
}
.loader-wrapper {
  height: 8em;
  align-items: center;
  display: flex;
}
.remove_padding {
  padding: 0;
}
.member .list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.member .heading {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
}

.member .list li {
  display: flex;
  align-items: center;
}

.member .list li .icon {
  margin-right: 15px;
}

.member .list li .icon .vue-feather {
  width: 15px;
}

.member .list li .text {
  font-size: 14px;
  color: #000000b5;
  text-transform: capitalize;
}
.detail_card_wrapper {
  margin: 10px;
}
.detail_card {
  width: 100%;
  text-align: left;
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 0px 0px 60px #d3e5e9;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
}

.heading_other {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  padding-left: 0;
  color: #42aea3;
}

.detail_card.invert {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #42aea3;
  margin-bottom: 10px;
}

.detail_card.mb {
  margin-bottom: 20px;
}

.detail_card .check_in_out_box {
  display: flex;
}

.detail_card .check_in_out_box.check_in_out_box_bottom {
  padding-bottom: 0 !important;
}

.detail_card .check_in_out_box .check_in_box:nth-child(1) {
  border-right: 1px solid #e9e9e9;
  padding-right: 15px;
  /* margin-right: 15px; */
}

.detail_card .check_in_out_box .check_in_box:nth-child(2) {
  padding-left: 20px !important;
}

.detail_card .check_in_box {
  padding-bottom: 5px;
}

.detail_card .check_in_box {
  width: 50%;
}

.detail_card .check_in_title {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 7px;
}

.detail_card .check_in_text {
  font-size: 18px;
  font-weight: 500;
  color: #42aea3;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}
.btn-add-new span {
  margin-top: 5px;
}
.btn-add-new {
  display: flex;
}
.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}
.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}
.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}
.trash-view i:hover {
  transform: translateY(-2px);
}
.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}
.error {
  color: #ea5455;
  font-size: 0.857rem;
}

.status-badge {
  position: relative;
  cursor: pointer;
}
.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}
.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}
.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}
.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}
.statusZindex {
  z-index: -1;
}
.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}
.mobileSearchDiv {
  position: relative;
}
.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}
.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}
.search-mobile:focus {
  border-color: #7367f0 !important;
}
/* .card-body, .content, .sectionBlock, input, select, span, .villaCls{
      background-color: #302a2a !important;
      color: #adabab !important; 
    } */

.ecommerce-application .search-product,
.ecommerce-application .input-group-text {
  height: 39px !important;
}

.ecommerce-application .dp__main:deep(.dp__input) {
  height: 39px;
}

.col-sm-6,
.col-sm-4 {
  margin-top: 10px;
}
</style>
